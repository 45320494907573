/**
 * đối tượng routes cho web và cms
 * @param { là tên không in hoa = tiếng anh viết liền, cách nhau bằng dấu _ và là duy nhất } name
 * @param { là đường dẫn trên trình duyệt, bắt đầu bằng / } path
 * @param { là đường dẫn đến file vật lý trong thư mục pages } component
 */
module.exports.routes = {
  // web
  web: [
    { path: '/', name: 'home', component: 'index' },
    { path: '/tmp', name: 'tmp', component: 'index' },
    { path: '/root', name: 'root', component: 'index' },
    { path: '/:lang([a-zA-Z]{1,2})', name: 'home2', component: 'index' },
    { path: '/usr/local', name: 'usr', component: 'index' },
    {
      path: '/api/:title([a-zA-Z0-9-]{1,10000})',
      name: 'api',
      component: 'api',
    },
    // chi tiết tin tức
    {
      path: '/:parentName([a-zA-Z0-9-]{3,10000})/:title([a-zA-Z0-9-]{1,10000})',
      name: 'Detail',
      component: 'User/Detail',
    },
    {
      path:
        '/:lang([a-zA-Z]{1,2})/:parentName([a-zA-Z0-9-]{3,10000})/:title([a-zA-Z0-9-]{1,10000})',
      name: 'Detail2',
      component: 'User/Detail',
    },
    // trang chuyên mục
    {
      path: '/:name([a-zA-Z0-9-]{3,10000})',
      name: 'category',
      component: 'User/Category',
    },
    {
      path: '/:lang([a-zA-Z]{1,2})/:name([a-zA-Z0-9-]{3,10000})',
      name: 'category2',
      component: 'User/Category',
    },
  ],
  dashboard: [],
};
